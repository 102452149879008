body {
    overflow-y: scroll; 
    overflow-x: hidden;
}

.App {
    text-align: center;
}

.header {
    height: 10%;
    background-color: #d0e0e0;
    text-align: center;
    padding: 5% 0;
    margin-bottom: 20px;
}

h3 {
    margin-top: 15px;
}

.noBulletList {
    list-style-type: none;
}

.noBulletList.noIndent {
    padding: 0;
}

.noBulletList a {
    text-decoration: underline;
    color: inherit;
}

.quote {
    width: 100vw;
    height: fit-content;
    position: relative;
    left: calc(-50vw + 50%);
    display: flex;
    align-items: center;
    font-size: 1.5em;   
    margin: 10px 0;        
}

.quoteText {
    padding: 3%;
    background-color: #d0e0e0; 
}

.teachLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teachRight {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.testLeft {
    display: flex;
    margin-bottom: 10px;
}

.testLeft p {
    width: 70%;
    padding: 10px;
    border-radius: 5px;
    text-align: justify;
}

.testRight {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}

.testRight p {
    width: 70%;
    padding: 10px;
    border-radius: 5px;
    text-align: justify;
}

.footer {
    background-color: #282c34;
    color: antiquewhite;
    min-height:50px;
    padding-top: 15px;
}

.footer iframe {
    margin: 20px 0;
}

.imageOverlay {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    font-size: 5em;
    z-index: 10;
    color: rgb(32, 32, 32);
    font-family: Verdana, sans-serif;
}

.titlebody {
    font-size: 4rem;
}

.spinnerImg {
    opacity: 0.5;
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 15;
}

.header { 
    min-height:50px; 
}

.carousel {
    height: 35vh;
    overflow: hidden;
}

#footerMap {
    width: 100%;
}

.navLogo {
    height: 3em;
}

.navbar-brand:hover {
    height: unset;
}

.justifyDiv {
    text-align: justify;
    margin-right: 3%;
}

.verticalSplit {
    display: flex;
    margin-bottom: 25px;
}

.imgRight {
    width: 50%;
}

.teacherLeftImg {
    height: max-content;
}

.tribute { 
    margin: 35px 0;
    text-align: center;
    color: rgb(35, 105, 170);
}

#homeImg {
    margin: -10px 0 20px -12px;;
}

@media (max-width: 575.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .imageOverlay {
        top: 30%;
        font-size: 3em;
    }

    .titlebody {
        font-size: 2rem;
    }

    .teachLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .teacherLeftImg {
        width: 95%;
    }

    .verticalSplit {
        flex-direction: column;
        align-items: center;
    }

    .imgRight {
        width: 100%;
    }

    .testLeft p,
    .testRight p {
        width: 100%;
    }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {  }

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {  }

/* The article fills all the space between header & footer */
.wrapper{ display:flex; flex-direction:column; min-height: 85vh; }
.bodyContent{ flex:1; }
